<template>
  <div> 
    <v-container fluid v-resize="onResize">
      <div class="mx-auto" :style="mobile ? '' : 'max-width: 600px;'">
        <!-- <v-sheet outlined class="pa-2" color="transparent"> -->
          <!-- <v-sheet outlined color="transparent"> -->
            <v-toolbar class="rounded-lg elevation-2">
              <v-skeleton-loader type="heading" width="200px" v-show="loading == true"/>
              <v-toolbar-title class="title" v-show="loading == false">Log</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn fab text small depressed v-show="loading == false" class="mr-n2">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-skeleton-loader type="image" class="rounded-circle mr-n2" width="40px" height="40px" v-show="loading == true"/>
            </v-toolbar>

            <!-- <v-divider></v-divider> -->

            <!-- <v-skeleton-loader type="list-item-two-line" width="200px" v-show="loading == true"></v-skeleton-loader> -->

            <v-timeline
              dense
              class="ml-n3"
              v-show="loading == false"
            >
              <v-timeline-item
                fill-dot
                :small="mobile ? false : true"
                v-for="log in Log"
                :key="log.Jam+log.Job"
                :icon="log.Job.includes('Login') ? 'mdi-login' : log.Job.includes('Logout') ? 'mdi-logout' : log.Job.includes('Mengubah') ? 'mdi-pencil' : 'mdi-account-plus'"
                :color="log.Job.includes('Login') ? 'green darken-3' : log.Job.includes('Logout') ? 'red darken-3' : log.Job.includes('Mengubah') ? 'blue darken-4' : 'blue darken-4'"
              >
                <v-card :outlined="$vuetify.theme.dark ? true : false" class="pl-3 pb-2 pt-1 mr-4 rounded-lg ml-n2">
                  <v-row dense class="pt-1">
                    <v-col cols="4" sm="3" md="2" lg="2">
                      <strong>{{log.Jam}}</strong>
                      <div class="text-caption">
                        {{log.Tanggal}}
                      </div>
                    </v-col>
                    <v-col cols="11" sm="8" md="9" lg="9">
                      <strong>{{log.KodeUser}}</strong>
                      <div class="text-caption">
                        {{log.Job}}
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          <!-- </v-sheet> -->
        <!-- </v-sheet> -->
      </div>

      <v-fab-transition>
        <v-btn
          :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey darken-1'"
          v-scroll="onScroll"
          v-show="fab"
          fab
          small
          dark
          fixed
          bottom
          right
          @click="toTop"
        >
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-container>

    <!-- <v-overlay class="fill-height" v-show="loading == true">
      <v-sheet outlined :color="$vuetify.theme.dark ? '' : 'white'" class="rounded-circle elevation-3 pa-1">
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            size="52"
            width="6"
            class="fill-height ma-0"
            color="error"
          >
            <v-img
              width="40px"
              class="mx-n2"
              src="../assets/LogoTrinity.png"
            ></v-img>
          </v-progress-circular>
        </v-row>
      </v-sheet>
    </v-overlay> -->

    <!-- <v-container fluid v-show="loading == false"> -->
      <!-- <v-toolbar flat class="transparent">
        <v-toolbar-title class="title">
          Logs
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon :color="$vuetify.theme.dark ? 'dark' : 'black'">mdi-magnify</v-icon>
        </v-btn>
        <v-btn text height="50px" class="mr-n3">
          <span class="subtitle-1 font-weight-bold mr-2">Filter</span>
          <v-icon :color="$vuetify.theme.dark ? 'dark' : 'black'">mdi-tune</v-icon>
        </v-btn>
      </v-toolbar> -->

      <!-- <v-card outlined class="rounded-lg"> -->
        <!-- <v-timeline
          align-top
          dense
        >
          <v-timeline-item
            fill-dot
            v-for="log in Log"
            :key="log.Jam+log.Job"
            :icon="log.Job.includes('Login') ? 'mdi-login' : log.Job.includes('Logout') ? 'mdi-logout' : log.Job.includes('Mengubah') ? 'mdi-pencil' : 'black'"
            :color="log.Job.includes('Login') ? 'green darken-3' : log.Job.includes('Logout') ? 'red darken-4' : log.Job.includes('Mengubah') ? 'blue darken-4' : 'black'"
          >
            <v-row dense class="pt-1">
              <v-col cols="4" sm="3" md="2" lg="2">
                <strong>{{log.Jam}}</strong>
                <div class="text-caption">
                  {{log.Tanggal}}
                </div>
              </v-col>
              <v-col cols="11" sm="8" md="9" lg="9">
                <strong>{{log.KodeUser}}</strong>
                <div class="text-caption">
                  {{log.Job}}
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline> -->
      <!-- </v-card> -->

        <!-- <v-toolbar flat dense color="dark">
          <v-icon left>mdi-notebook</v-icon>
          <v-toolbar-title>Log</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>
        <v-flex style="height:550px;overflow-y:auto;overflow-x:hidden;" >
          <v-row dense class="pa-3">
            <v-col cols="12" md="9">
              <v-skeleton-loader
                v-for="n in 3"
                :key="n"
                height="22"
                type="image"
                class="mx-3 mt-3 rounded-xl"
                v-show="loading == true"
              ></v-skeleton-loader>
            </v-col>


            <v-col cols="12" sm="12" md="12" v-show="loading == false">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" width=100><v-icon left>mdi-clock-outline</v-icon>Jam</th>
                      <th class="text-left" width=120><v-icon left>mdi-calendar-outline</v-icon>Tanggal</th>
                      <th class="text-left" width=170><v-icon left>mdi-account-box-outline</v-icon>KodeUser</th>
                      <th class="text-left" width=300><v-icon left>mdi-history</v-icon>Aktivitas</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="log in Log"
                      :key="log.Jam+log.Job"
                    >
                      <td>{{log.Jam}}</td>
                      <td>{{log.Tanggal}}</td>
                      <td>{{log.KodeUser}}</td>
                      <td>{{log.Job}}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-flex> -->
    <!-- </v-container> -->
  </div>
</template>

<script>
import api from "@/services/http";
  export default {
    data: () => ({
      fab: false,
      mobile:null,
      windowSize: {x: 0, y: 0},
      loading:true,
      DialogUserMenu: false,
      editedIndex: -1,
      token:null,
      MenuTemp:[],
      ParentTemp:[],
      ObjectTemp:[],
      ParentSelect:null,
      JabatanSelect:null,
      resMenu:[],
      defaultItem: {
        KodeMenu: "",
        Nama: "",
        Object: "",
        UserMenu: "",
        Visible: "",
        Icon: ""
        
      },
      editedItem: {
        KodeMenu: "",
        Nama: "",
        Object: "",
        UserMenu: "",
        Visible: "",
        Icon: ""
        
      },
      Log:[],
      Beranda: [
        // { Nama: 'Beranda'},
      ],

      Master: [
        // {
        //   items: [
        //     { Nama: 'Karyawan' },
        //     { Nama: 'UserMenu' }

        //   ],
        //   Nama: 'Master',
        // },
      ],
    }),

    mounted(){
      this.token = localStorage.getItem('token')
      this.getLog()
      this.ee()
    },

    computed: {
      formTitleUserMenu () {
        return this.editedIndex === -1 ? 'Tambah User Menu' : 'Ubah User Menu'
      },
    },

    watch: {
      windowSize(){
        if (this.windowSize.x < 700) {
          this.titleClass = "d-none"
          this.mobile = true
        }else{
          this.titleClass = "mr-4"
          this.mobile = false
        }
      },

      DialogUserMenu(val) {
        val || this.KeluarDialogUserMenu();
        if (this.DialogUserMenu == true) {
          this.ObjectTemp = ['List','Link']
          api.get('menuselected/'+this.JabatanSelect+'?token='+this.token).then(res=>{
            this.MenuTemp = res.data
            var parent = res.data.Menu.filter( function(item){return (item.Parent == null && item.Object != "Link");} );
            this.ParentTemp = parent
          })
        }
      },
      ParentSelect(){
        if (this.ParentSelect == null || this.ParentSelect.length == 0) {
          //do nothing
          this.ObjectTemp = [...new Array]
          this.ObjectTemp = ['List','Link']
        }else if(this.ParentSelect.Object == "List") {
          this.ObjectTemp = ['Page']
        }else{
          //do nothing
        }
      }
    },

    DialogUserMenu (val) {
      val || this.KeluarDialogUserMenu()
    },

    methods: {
      onScroll (e) {
        if (typeof window === 'undefined') return
        const top = window.pageYOffset ||   e.target.scrollTop || 0
        this.fab = top > 20
      },
      toTop () {
        this.$vuetify.goTo(0)
      },
      
      onResize(){
        this.windowSize = { x: window.innerWidth, y: window.innerHeight }
      },

      getLog:function(){
        api.get('log').then(
        res=>{
          this.loading = false
          let a = JSON.stringify(res.data)
          this.Log = [...new Array]
          this.Log = JSON.parse(a)
        //   this.Log = a
        })
        // api.get('/log').then(
		// 		res => {
        //   console.log(res.data)
        //   this.Log = res.data
		// 		},
		// 		err => {
		// 			console.log(err);
		// 		}
		// 	)
      },
      ee(){
        // let url = "http://192.168.1.76:8000/log"
        // fetch(url)
        // .then((resp) => resp.json())
      },

      KeluarDialogUserMenu () {
        this.DialogUserMenu = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.ParentSelect = null
          this.editedIndex = -1
        })
      },

      Reset() {
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        })
      },
    },
  }
</script>

<style>
/* table th + th { border-left:1px solid #dddddd; }
table td + td { border-left:1px solid #dddddd; } */
</style>